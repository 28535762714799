/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    pageNotFound: 'Página no encontrada',
    returnTo: 'Volver a',
  },
  form: {
    emailPattern: 'El formato del e-mail no es valido.',
    required: 'Este campo es requerido',
    passwordPatternError:
      'Requiere al menos 8 caracteres, con mayúsculas, minusculas y al menos un número.',
    passwordMatchError: 'Las contraseñas no coinciden',
    changePassword: 'Cambiar contraseña',
  },
  payment: {
    donate: 'Donar',
    processing: 'Procesando',
    donationTotal: 'Total de donación',
    addCard: 'Agregar Tarjeta de Crédito',
    applePay: 'Usar Apple Pay',
    availableCards: 'Tarjetas de Crédito disponibles',
    incomplete_number: 'Falta el número de tarjeta o está incompleto.',
    incomplete_expiry:
      'Falta la fecha de expiración de tarjeta de crédito o está incompleta.',
    incomplete_cvc:
      'Falta el código CVC de tarjeta de crédito o está incompleto',
    incomplete_zip: 'Falta el código posta o está incompleto',
    card_declined: 'Tarjeta de Crédito rechazada',
    payment_intent_unexpected_state: 'No hemos podido completar la transacción',
    payment_canceled: 'Pago cancelado',
    payment_completed: 'Pago finalizado',
  },
  verifyAccount: {
    title: 'Verifica tu cuenta',
    missingParamsMessage: 'Faltan algunos parámetros.',
    successMessage: 'Tu cuenta ha sido verificada correctamente.',
    errorMessage:
      'Hubo un error intentando verificar tu cuenta. Quizás el enlace de verificación ya no es válido.',
  },
  resetPassword: {
    title: 'Obtener una nueva contraseña',
    resetPassword: 'Restablecer contraseña',
    newPassword: 'Nueva contraseña',
    newPasswordConfirm: 'Repetir nueva contraseña',
    missingParamsMessage: 'Faltan algunos parámetros',
    successMessage: 'Tu nueva contraseña a sido configurada con éxito',
    errorMessage:
      'Hubo un error intentando cambiar tu contraseña. Quizás el enlace ya no es válido.',
  },
};
