import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import app from 'config/app';
import Message from 'ui/Message';

function PaymentCanceled() {
  const { t } = useTranslation('payment');

  useEffect(() => {
    document.title = `${app.name} | ${t('title')}`;
  }, [t]);

  return <Message error className="m-4" message={t('payment_canceled')} />;
}

export default PaymentCanceled;
