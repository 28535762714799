import React from 'react';
import ReactDOM from 'react-dom';

import MainRouter from './screens/Router';

ReactDOM.render(
  <React.StrictMode>
    <MainRouter />
  </React.StrictMode>,
  document.getElementById('root'),
);
