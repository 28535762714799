import React from 'react';

import { useForm, FormProvider } from 'react-hook-form';

// Form wrapper
function Form({ children, defaultValues, onSubmit, className }) {
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;
