/**
 * Performs an action with every substring that matches the regexp in a string
 *
 * @param {string} string
 * @param {regexp} regExp
 * @param {function} action
 */
export function withEveryMatch(string, regExp, action = (x) => x) {
  const substrings = string.match(new RegExp(regExp, 'g')) || [];

  for (const substring of substrings) {
    action(substring.match(regExp));
  }
}
