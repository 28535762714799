export function isSet(value, type) {
  return value !== undefined || value !== null;
}

export function isTypeOf(value, type) {
  return isSet(value) && typeof value === type;
}

/**
 * Checks if provided value is a function
 *
 * @param {*} value Value to be checked
 */
export function isFunction(value) {
  return isTypeOf(value, 'function');
}

/**
 * Checks if provided value is a string
 *
 * @param {*} value Value to be checked
 */
export function isString(value) {
  return typeof value === 'string';
}
