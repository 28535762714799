import fetchData, { setClientToken, setAuthToken } from 'utils/fetchData';
import { isFunction } from 'utils/typeChecks';
import { replaceParams } from 'utils/urls';

import apiConf from 'config/api';

const { endpoints } = apiConf;

function getRequest(endpoint, params) {
  const { url, method } = endpoints[endpoint] || {};

  if (!url) return null;

  const requestParams = replaceParams(url, params);
  const requestUrl = `${apiConf.rootUrl}${requestParams}`;

  return {
    requestUrl,
    method,
  };
}

export default async function apiCall({
  endpoint,
  data,
  query,
  params,
  token,
  onLoading,
  onStart,
  onSuccess,
  onError,
  onFinish,
}) {
  const request = getRequest(endpoint, params);

  try {
    if (request) {
      // sets request client token
      setClientToken(apiConf.clientToken);

      // sets auth token, if sent
      if (token) setAuthToken(token);

      const response = await fetchData(request.requestUrl, {
        loading: onLoading,
        method: request.method,
        data,
        params: query,
      });

      if (isFunction(onStart))
        onStart(response.error ? response.error.code : null);

      if (response.error) {
        console.error(response.error, { request }); // eslint-disable-line no-console
        if (isFunction(onError)) onError(response.error);
      } else {
        if (isFunction(onSuccess)) onSuccess(response);
      }
    } else {
      onError({ error: { name: 'no-endpoint', endpoint } });
    }
    if (onFinish) onFinish();
  } catch (error) {
    console.error(error, { request }); // eslint-disable-line no-console
    onError({ error: { name: 'fetch-failed', endpoint } });
  }
}
