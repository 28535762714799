import React, { useMemo } from 'react';

import icons from './icons';

const svgStyle = { width: '1.25em', height: '1em' };

export default function Icon({ className = '', name = 'user', spin = false }) {
  const icon = useMemo(() => icons[name], [name]);

  if (!icon) return null;

  return (
    <svg
      aria-hidden="true"
      className={`fill-current ${spin ? 'spinner' : ''} ${className}`}
      focusable="false"
      role="img"
      style={svgStyle}
      viewBox={icon.viewBox || '0 0 512 512'}
      xmlns="http://www.w3.org/2000/svg"
    >
      {icon.svg}
    </svg>
  );
}
