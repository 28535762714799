import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import NotFound from './NotFound';
import PaymentCanceled from './PaymentCanceled';
import PaymentFinished from './PaymentFinished';
import ResetPassword from './ResetPassword';
import StripePayment from './StripePayment';
import VerifyAccount from './VerifyAccount';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/payment">
          <StripePayment />
        </Route>
        <Route path="/payment-finished">
          <PaymentFinished />
        </Route>
        <Route path="/payment-canceled">
          <PaymentCanceled />
        </Route>
        <Route path="/reset-password/:token">
          <ResetPassword />
        </Route>
        <Route path="/verify/:uid/:token">
          <VerifyAccount />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
