/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    pageNotFound: 'Seite nicht gefunden',
    returnTo: 'Zürück',
  },
  form: {
    emailPattern: 'Email hat ungültiges Format',
    required: 'Eingabe erforderlich',
    passwordPatternError:
      'Passwort mus 8 Zeichen haben, Groß-/Kleinbuchstaben und mindestens 1 Zahl enthalten.',
    passwordMatchError: 'Passwörter stimmen nicht überein!',
    changePassword: 'Passwort ändern',
  },
  payment: {
    donate: 'Spenden',
    processing: 'in Bearbeitung',
    donationTotal: 'Gesamt',
    addCard: 'Kreditkarte hinzufügen',
    applePay: 'Apple Pay verwenden',
    availableCards: 'Verfügbare Kreditkarten',
    incomplete_number: 'Kartennummer fehlt oder nicht komplett',
    incomplete_expiry: 'Ablaufdatum fehlt oder ungültig',
    incomplete_cvc: 'CVC Code fehlt oder nicht komplett',
    incomplete_zip: 'Postleitzahl fehlt oder nicht komplett',
    card_declined: 'Karte ungültig',
    payment_intent_unexpected_state: 'Wir konnten Ihre Zahlung nicht ausführen',
    payment_canceled: 'Zahlung storniert',
    payment_completed: 'Zahlung ausgeführt',
  },
  verifyAccount: {
    title: 'Konto verifizieren',
    missingParamsMessage: 'Es fehlen Angaben!',
    successMessage: 'Ihr Konto wurde erfolgreich verifiziert.',
    errorMessage: 'Fehler beim verifizieren des Kontos. Ungültiger Link?',
  },
  resetPassword: {
    title: 'Passwort zurücksetzten',
    resetPassword: 'Passwort zurücksetzen',
    newPassword: 'Neues Passwort',
    newPasswordConfirm: 'Neues Passwort bestätigen',
    missingParamsMessage: 'Es fehlen einige Angaben!',
    successMessage: 'Ihr neues Passwort wurde erfolgreich gespeichert!',
    errorMessage: 'Fehler beim Einrichten des Passwortes. Ungültiger Link?',
  },
};
