/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    pageNotFound: 'Page not found',
    returnTo: 'Return to',
  },
  form: {
    emailPattern: 'The e-mail has an invalid format',
    required: 'This field is required',
    passwordPatternError:
      'Must be at least 8 characters long and include letters in upper- and lowercase, and at least one number.',
    passwordMatchError: 'Passwords do not match!',
    changePassword: 'Change Password',
  },
  payment: {
    donate: 'Donate',
    processing: 'Processing',
    donationTotal: 'Donation total',
    addCard: 'Add a credit card',
    applePay: 'Use Apple Pay',
    availableCards: 'Available Credit Cards',
    incomplete_number: 'Card number is missing or incomplete',
    incomplete_expiry: 'Card expiry date is missing or incomplete',
    incomplete_cvc: 'Card CVC code is missing or incomplete',
    incomplete_zip: 'Zip code is missing or incomplete',
    card_declined: 'Card declined',
    payment_intent_unexpected_state: "We weren't able to complete your payment",
    payment_canceled: 'Payment canceled',
    payment_completed: 'Payment completed',
  },
  verifyAccount: {
    title: 'Verify your account',
    missingParamsMessage: 'Some parameters are missing!',
    successMessage: 'Your account was successfully verified.',
    errorMessage:
      'There was an error trying to verify your account. Maybe the link is no longer valid.',
  },
  resetPassword: {
    title: 'Reset your password',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    newPasswordConfirm: 'Confirm New Password',
    missingParamsMessage: 'Some parameters are missing!',
    successMessage: 'Your new password was successfully saved!',
    errorMessage:
      'There was an error trying to set your new password. Maybe the link is no longer valid.',
  },
};
