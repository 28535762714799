import React from 'react';

import Button from 'ui/Button';

import { useFormContext } from 'react-hook-form';

export default function Submit({ label }) {
  const { formState } = useFormContext();
  const { isValid } = formState;

  return (
    <div className="flex items-center justify-center">
      <Button label={label} type="submit" icon="check" disabled={!isValid} />
    </div>
  );
}
