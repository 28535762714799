import React from 'react';

import Icon from 'ui/Icon';

const types = {
  default: {
    wrap: 'bg-white border-blue-600',
    text: 'text-blue-600',
  },
  disabled: {
    wrap: 'bg-gray-600 border-transparent',
    text: 'text-gray-400',
  },
  submit: {
    type: 'submit',
    wrap: 'bg-green-500 border-green-700',
    text: 'text-white',
  },
  primary: {
    wrap: 'bg-blue-500 border-blue-700',
    text: 'text-white',
  },
  danger: {
    wrap: 'bg-red-500 border-red-700',
    text: 'text-white',
  },
  hollow: {
    wrap: 'bg-transparent border-white',
    text: 'text-white',
  },
  link: {
    wrap: 'bg-transparent border-transparent',
    text: 'text-blue-500',
  },
  'link-light': {
    wrap: 'bg-transparent border-transparent',
    text: 'text-white',
  },
};

const sizes = {
  xs: {
    wrap: 'px-2 py-1',
    text: 'text-xs',
  },
  sm: {
    wrap: 'px-3 py-2',
    text: 'text-sm leading-4 pt-px',
  },
  default: {
    wrap: 'px-4 py-2',
    text: 'text-base leading-6',
  },
  lg: {
    wrap: 'px-5 py-3',
    text: 'text-lg leading-7',
  },
};

export default function Button({
  className = '',
  icon,
  label,
  type = 'default',
  size = 'default',
  loading = false,
  disabled = false,
}) {
  const typeClass = types[disabled || loading ? 'disabled' : type];
  const sizeClass = sizes[size];

  return (
    <button
      type={typeClass.type || 'button'}
      className={`flex justify-center items-center flex-row rounded-full border border-transparent ${typeClass.wrap} ${typeClass.text} ${sizeClass.wrap} ${sizeClass.text} ${className}`}
      disabled={disabled || loading}
    >
      {(loading || icon) && (
        <span className="flex items-center justify-center mr-2">
          <Icon name={loading ? 'spinner' : icon} spin={loading} />
        </span>
      )}
      <span className="font-semibold uppercase">{label}</span>
    </button>
  );
}
