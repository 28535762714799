import React from 'react';

import { useTranslation } from 'react-i18next';

import Icon from './Icon';
import Section from './Section';

const markTypes = {
  info: {
    wrap: 'bg-blue-600 border-blue-800',
    icon: '',
  },
  required: {
    wrap: 'bg-orange-500 border-orange-600',
    icon: 'asterisk',
  },
  error: {
    wrap: 'bg-red-600 border-red-800',
    icon: 'exclamation',
  },
  success: {
    wrap: 'bg-green-400 border-green-600',
    icon: 'check',
  },
};

export function Mark({ type }) {
  const style = markTypes[type] ?? markTypes.info;
  return (
    <div
      className={`flex justify-center items-center w-5 h-5 p-1 border rounded-full ${style.wrap}`}
    >
      <Icon name={style.icon} className="text-white" />
    </div>
  );
}

function ErrorDisplay({ message }) {
  return (
    <div className="px-2 py-1 leading-3 bg-red-600 border border-b-2 border-red-800 rounded-md">
      <span className="text-xs font-semibold text-white">{`${message}`}</span>
    </div>
  );
}

export default function Field({
  children,
  className,
  label,
  error,
  required,
  hasFocus,
  hasValue,
  onlyInput = false,
  showChecked = true,
  showError = true,
  showRequired = true,
}) {
  const { t } = useTranslation('form');
  if (onlyInput) {
    return children;
  }

  return (
    <Section
      className={`w-full ${className || ''}`}
      innerClass={`border border-b-4 border-transparent ${
        hasFocus ? 'border-blue-400' : 'border-blue-200'
      }`}
      title={label}
      rounded
      notes={error && <ErrorDisplay message={t(error.message || error.type)} />}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex-grow">{children}</div>
        {(error || required) && (
          <div className="pl-1 pr-3">
            {error
              ? showError && <Mark type="error" />
              : required
              ? hasValue
                ? showChecked && <Mark type="success" />
                : showRequired && <Mark type="required" />
              : null}
          </div>
        )}
      </div>
    </Section>
  );
}
