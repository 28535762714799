import React from 'react';

const styleModes = {
  light: {
    heading: 'px-2',
    title: 'text-white',
    inner: 'bg-white',
    moreLink: 'text-white',
    error: 'text-red-400',
  },
  default: {
    heading: 'px-2',
    title: 'text-gray-600',
    inner: 'bg-white',
    moreLink: 'text-blue-600',
    error: 'text-red-500',
  },
  simple: {
    heading: '',
    title: 'text-gray-600',
    inner: 'border-t border-gray-400 py-2 px-1',
    moreLink: 'text-blue-600',
    error: 'text-red-500',
  },
};

export default function Section({
  className = '',
  innerClass,
  children,
  title,
  mode = 'default', // 'default', 'light', 'simple'
  more,
  notes,
  rounded,
  to,
}) {
  const styles = styleModes[mode];

  return (
    <div className={className}>
      {title && (
        <div
          className={`flex flex-row justify-between pt-4 pb-2 ${styles.heading}`}
        >
          <span className={`uppercase ${styles.title}`}>{title}</span>
          {more && (
            <a href={to}>
              <span className={`text-sm uppercase ${styles.moreLink}`}>
                {more}
              </span>
            </a>
          )}
        </div>
      )}
      <div
        className={`flex flex-row ${
          rounded ? 'rounded-lg' : ''
        } ${innerClass}  ${styles.inner}`}
      >
        <div className="flex-1">{children}</div>
      </div>
      {notes}
    </div>
  );
}
