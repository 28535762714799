import React from 'react';

const icons = {
  asterisk: {
    svg: (
      <path d="M479.31 357.216L303.999 256l175.31-101.215c5.74-3.314 7.706-10.653 4.392-16.392l-12-20.785c-3.314-5.74-10.653-7.706-16.392-4.392L280 214.431V12c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v202.431L56.69 113.215c-5.74-3.314-13.079-1.347-16.392 4.392l-12 20.785c-3.314 5.74-1.347 13.079 4.392 16.392L208 256 32.69 357.216c-5.74 3.314-7.706 10.653-4.392 16.392l12 20.784c3.314 5.739 10.653 7.706 16.392 4.392L232 297.569V500c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V297.569l175.31 101.215c5.74 3.314 13.078 1.347 16.392-4.392l12-20.784c3.314-5.739 1.347-13.079-4.392-16.392z"></path>
    ),
  },
  spinner: {
    svg: (
      <path d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z" />
    ),
  },
  donate: {
    svg: (
      <path d="M225.6 232.3l50.1 14.3c3.6 1 6.1 4.4 6.1 8.1 0 4.6-3.8 8.4-8.4 8.4h-32.8c-3.6 0-7.1-.8-10.3-2.2-4.8-2.2-10.4-1.7-14.1 2l-17.5 17.5c-5.3 5.3-4.7 14.3 1.5 18.4 9.5 6.3 20.4 10.1 31.8 11.5V328c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-17.6c30.3-3.6 53.4-31 49.3-63-2.9-23-20.7-41.3-42.9-47.7l-50.1-14.3c-3.6-1-6.1-4.4-6.1-8.1 0-4.6 3.8-8.4 8.4-8.4h32.8c3.6 0 7.1.8 10.3 2.2 4.8 2.2 10.4 1.7 14.1-2l17.5-17.5c5.3-5.3 4.7-14.3-1.5-18.4-9.5-6.3-20.4-10.1-31.8-11.5V104c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v17.6c-30.3 3.6-53.4 31-49.3 63 2.9 23 20.6 41.3 42.9 47.7zM480 320h-34.7c17-30.9 26.7-66.3 26.7-104C472 96.7 375.3 0 256 0S40 96.7 40 216c0 37.7 9.7 73.1 26.7 104H32c-17.7 0-32 17.2-32 38.4v115.2C0 494.8 14.3 512 32 512h448c17.7 0 32-17.2 32-38.4V358.4c0-21.2-14.3-38.4-32-38.4zM256 48c92.6 0 168 75.4 168 168s-75.4 168-168 168S88 308.6 88 216 163.4 48 256 48zm208 416H48v-96h54.6c12.2 12.3 25.9 22.9 40.7 32H104c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h304c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-39.3c14.8-9.1 28.5-19.7 40.7-32H464v96z" />
    ),
  },
  exclamation: {
    viewBox: '0 0 256 512',
    svg: (
      <path d="M173.854 48c6.874 0 12.343 5.763 11.984 12.628l-11.742 224c-.334 6.375-5.6 11.372-11.984 11.372H93.888c-6.383 0-11.65-4.997-11.984-11.372l-11.742-224C69.802 53.763 75.271 48 82.146 48h91.708M128 336c35.29 0 64 28.71 64 64s-28.71 64-64 64-64-28.71-64-64 28.71-64 64-64M173.854 0H82.146C47.881 0 20.427 28.783 22.228 63.141l11.742 224c.698 13.309 5.689 25.414 13.592 35.001C28.035 342.31 16 369.777 16 400c0 61.757 50.243 112 112 112s112-50.243 112-112c0-30.223-12.035-57.69-31.561-77.858a59.78 59.78 0 0 0 13.592-35.001l11.742-224C235.566 28.922 208.259 0 173.854 0z"></path>
    ),
  },
  eye: {
    svg: (
      <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
    ),
  },
  'eye-slash': {
    svg: (
      <path d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path>
    ),
  },
  check: {
    svg: (
      <path d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path>
    ),
  },
  'check-circle': {
    svg: (
      <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
    ),
  },
  circle: {
    svg: (
      <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
    ),
  },

  card: {
    viewBox: '0 0 576 512',
    svg: (
      <path d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z" />
    ),
  },

  // Credit Cards Icons:
  amex: {
    viewBox: '0 0 100 60',
    svg: (
      <g fill="none" fillRule="evenodd">
        <path
          fill="#006FCF"
          d="M64.754717,18.2830189 L66.2735849,14.2688679 L73,14.2688679 L73,7 L27,7 L27,53 L73,53 L73,45.7311321 L66.5990566,45.7311321 L64.2122642,43.0188679 L61.8254717,45.7311321 L43.8160377,45.7311321 L43.8160377,31.1933962 L37.8490566,31.1933962 L45.3349057,14.3773585 L52.6037736,14.3773585 L54.3396226,18.1745283 L54.3396226,14.3773585 L63.3443396,14.3773585 L64.754717,18.2830189 Z M59.7641509,20.995283 L59.7641509,19.3679245 L60.4150943,20.995283 L63.4528302,29.0235849 L66.2735849,29.0235849 L69.3113208,20.995283 L69.8537736,19.3679245 L69.8537736,28.9150943 L73,28.9150943 L73,16.6556604 L67.7924528,16.6556604 L65.4056604,22.8396226 L64.754717,24.5754717 L64.1037736,22.8396226 L61.7169811,16.6556604 L56.509434,16.6556604 L56.509434,28.9150943 L59.7641509,28.9150943 L59.7641509,20.995283 Z M53.0377358,28.9150943 L56.6179245,28.9150943 L51.1933962,16.6556604 L46.9622642,16.6556604 L41.5377358,28.9150943 L45.1179245,28.9150943 L46.0943396,26.5283019 L52.0613208,26.5283019 L53.0377358,28.9150943 Z M48.4811321,20.8867925 L49.1320755,19.3679245 L49.7830189,20.8867925 L51.0849057,24.0330189 L47.2877358,24.0330189 L48.4811321,20.8867925 Z M46.3113208,31.1933962 L46.3113208,43.4528302 L56.509434,43.4528302 L56.509434,40.8490566 L49.3490566,40.8490566 L49.3490566,38.6792453 L56.2924528,38.6792453 L56.2924528,36.0754717 L49.3490566,36.0754717 L49.3490566,33.9056604 L56.509434,33.9056604 L56.509434,31.3018868 L46.3113208,31.1933962 Z M68.009434,43.3443396 L72.0235849,43.3443396 L66.2735849,37.2688679 L72.0235849,31.1933962 L68.009434,31.1933962 L64.3207547,35.2075472 L60.6320755,31.1933962 L56.509434,31.1933962 L62.259434,37.3773585 L56.509434,43.4528302 L60.4150943,43.4528302 L64.1037736,39.4386792 L68.009434,43.3443396 Z M69.5283019,37.2688679 L73,40.8490566 L73,33.7971698 L69.5283019,37.2688679 Z"
        />
      </g>
    ),
  },
  mastercard: {
    viewBox: '0 0 100 60',
    svg: (
      <g fill="none" fillRule="evenodd" transform="translate(13 7)">
        <path fill="#FF5F00" d="M26.2 5.1H48.099999999999994V40.9H26.2z" />
        <path
          fill="#EB001B"
          d="M28.4235897,23.0538462 C28.4180855,16.0681206 31.6249491,9.46781217 37.1201709,5.15470085 C27.7846537,-2.18201853 14.3784766,-1.11336191 6.32314055,7.60964757 C-1.7321955,16.332657 -1.7321955,29.78136 6.32314055,38.5043695 C14.3784766,47.227379 27.7846537,48.2960356 37.1201709,40.9593162 C31.6232004,36.6448353 28.4161408,30.0417912 28.4235897,23.0538462 Z"
        />
        <path
          fill="#F79E1B"
          d="M73.9620513,23.0538462 C73.9618481,31.7715397 68.9840782,39.7238643 61.1428196,43.5334372 C53.301561,47.34301 43.9734499,46.3410104 37.1201709,40.9529915 C42.612995,36.6362184 45.8201001,30.0367875 45.8201001,23.0506838 C45.8201001,16.06458 42.612995,9.4651491 37.1201709,5.14837607 C43.9734499,-0.239642881 53.301561,-1.24164247 61.1428196,2.56793036 C68.9840782,6.3775032 73.9618481,14.3298278 73.9620513,23.0475214 L73.9620513,23.0538462 Z"
        />
      </g>
    ),
  },
  visa: {
    viewBox: '0 0 100 60',
    svg: (
      <g fill="none" fillRule="evenodd" transform="translate(6 16)">
        <path
          fill="#004E89"
          d="M38.4 28L31 28 35.6 1 43 1 38.4 28M24.4263413 1L17.7185717 19.5709612 16.923883 15.571556 16.923883 15.5727033 14.5570429 3.43107844C14.5570429 3.43107844 14.2722412 1 11.2198098 1L.129768527 1 0 1.45776324C0 1.45776324 3.39120761 2.1610436 7.36120581 4.54278916L13.4741073 28 20.8054549 28 32 1 24.4263413 1M72.8152124 18.4500722L76.505918 8.34371548 78.5840903 18.4500722 72.8152124 18.4500722zM80.5475732 28L87 28 81.373337 1 75.724883 1C73.1156987 1 72.4803193 3.01117532 72.4803193 3.01117532L62 28 69.3240664 28 70.7897972 23.9902694 79.7229562 23.9902694 80.5475732 28z"
        />
        <path
          fill="#F9A533"
          d="M17,16 L14.6225148,3.50236183 C14.6225148,3.50236183 14.3364321,1 11.2702721,1 L0.130352175,1 L0,1.47118564 C0,1.47118564 5.35597476,2.60604629 10.4904662,6.86088805 C15.4011671,10.9303259 17,16 17,16"
        />
        <path
          fill="#004E89"
          d="M61.9980894,6.98821887 L63,1.17926194 C63,1.17926194 59.9059992,0 56.6836072,0 C53.1964081,0 44.9197554,1.5275235 44.9197554,8.95480481 C44.9197554,15.9441731 54.6350783,16.0303764 54.6350783,19.6991913 C54.6350783,23.3691556 45.9205197,22.7140101 43.0443256,20.3991626 L42,26.4701777 C42,26.4701777 45.1352694,28 49.9293084,28 C54.7210546,28 61.9545281,25.510447 61.9545281,18.7394606 C61.9545281,11.706416 52.1520825,11.0501211 52.1520825,7.99277534 C52.1520825,4.9342802 58.9923577,5.32736751 61.9980894,6.98821887"
        />
      </g>
    ),
  },
};

export default icons;
