// Using axios: https://github.com/mzabriskie/axios
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.validateStatus = (status) => {
  return status >= 200 && status < 500;
};

export const setClientToken = (token) => {
  if (token !== axios.defaults.headers.common.ClientToken)
    axios.defaults.headers.common.ClientToken = token;
};

export const setAuthToken = (token) =>
  (axios.defaults.headers.common.Authorization = token);

export default async function fetchData(
  url,
  { method = 'get', data, params, headers, loading = (value) => value },
) {
  loading(true);

  const req = {
    url,
    method,
    data,
    params,
    headers: {
      ...axios.defaults.headers.common,
      ...headers,
    },
  };

  try {
    const res = await axios(req);
    const json = await res.data;
    loading(false);
    return json.error ? { error: json.error || 'UNDEFINED_ERROR', req } : json;
  } catch (e) {
    loading(false);
    const res = e.response
      ? {
          error: 'FETCH_DATA_FAILED',
          status: e.response.status,
          statusText: e.response.statusText,
          req,
        }
      : {
          error: 'SERVER_OFFLINE',
          status: '400',
          statusText: 'Server is not reachable',
          req,
        };
    return res;
  }
}
