import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import app from 'config/app';
import Layout from 'ui/Layout';
import apiCall from 'utils/apiCall';

function VerifyAccount() {
  const { t } = useTranslation('verifyAccount');
  const { uid, token } = useParams();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    document.title = `${app.name} | ${t('title')}`;
  }, [t]);

  useEffect(() => {
    if (!uid || !token) {
      setMessage('missingParamsMessage');
      setError(false);
    } else {
      apiCall({
        endpoint: 'verifyAccount',
        query: {
          uid,
          token,
        },
        onSuccess: () => {
          setMessage('successMessage');
          setError(false);
        },
        onError: () => {
          setMessage('errorMessage');
          setError(true);
        },
      });
    }
  }, [uid, token]);

  return <Layout title={t('title')} message={t(message)} error={error} />;
}

export default VerifyAccount;
