/* eslint-disable import/no-anonymous-default-export */
export default {
  common: {
    pageNotFound: 'Pagina non trovata',
    returnTo: 'Ritorno a',
  },
  form: {
    emailPattern: "L'e-mail ha un formato non valido",
    required: 'Questo campo è obbligatorio',
    passwordPatternError:
      'Deve essere lungo almeno 8 caratteri e includere lettere maiuscole e minuscole e almeno un numero.',
    passwordMatchError: 'Le password non coincidono!',
    changePassword: 'Cambia la password',
  },
  payment: {
    donate: 'Dona',
    processing: 'In lavorazione',
    donationTotal: 'Donazione totale',
    addCard: 'Aggiungi una carta di credito',
    applePay: 'Usa Apple Pay',
    availableCards: 'Carte di credito disponibili',
    incomplete_number: 'Il numero della carta è mancante o incompleto',
    incomplete_expiry:
      'La data di scadenza della carta è mancante o incompleta',
    incomplete_cvc: 'Il codice CVC della carta è mancante o incompleto',
    incomplete_zip: 'Il codice postale è mancante o incompleto',
    card_declined: 'Carta rifiutata',
    payment_intent_unexpected_state:
      'Non siamo stati in grado di completare il tuo pagamento',
    payment_canceled: 'Pagamento annullato',
    payment_completed: 'Pagamento completato',
  },
  verifyAccount: {
    title: 'Verifica il tuo account',
    missingParamsMessage: 'Mancano alcuni parametri!',
    successMessage: 'Il tuo account è stato verificato con successo.',
    errorMessage:
      "C'è stato un errore nel tentativo di verificare il tuo account. Forse il link non è più valido.",
  },
  resetPassword: {
    title: 'Ripristina la tua password',
    resetPassword: 'Ripristina la password',
    newPassword: 'Nuova Password',
    newPasswordConfirm: 'Conferma la nuova Password',
    missingParamsMessage: 'Mancano alcuni parametri!',
    successMessage: 'La tua nuova password è stata salvata con successo!',
    errorMessage:
      "C'è stato un errore nel tentativo di impostare la tua nuova password. Forse il link non è più valido.",
  },
};
