import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import app from 'config/app';
import Message from 'ui/Message';

function PaymentFinished() {
  const { t } = useTranslation('payment');

  useEffect(() => {
    document.title = `${app.name} | ${t('title')}`;
  }, [t]);

  return <Message className="m-4" message={t('payment_completed')} />;
}

export default PaymentFinished;
