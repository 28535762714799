/* eslint-disable import/no-anonymous-default-export */
export default {
  rootUrl: process.env.REACT_APP_API_URL,
  clientToken: process.env.REACT_APP_CLIENT_TOKEN,
  endpoints: {
    verifyAccount: {
      method: 'get',
      url: '/donors/confirm',
    },
    resetPassword: {
      method: 'post',
      url: '/donors/reset-password',
    },
    setProcessingStarted: {
      method: 'post',
      url: '/transactions/{id}/processing-started',
    },
  },
};
