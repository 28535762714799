import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from './Icon';

export default function Message({ className, message, error }) {
  const { t } = useTranslation();

  return (
    <div
      className={`flex justify-center items-center rounded transition-all duration-300 ease-in-out ${className} ${
        error ? 'bg-red-500' : 'bg-green-500'
      } ${message ? 'py-2 px-3' : 'h-0 m-0 p-0'}`}
    >
      <Icon
        name={error ? 'exclamation' : 'check-circle'}
        className="mr-2 text-white"
      />
      <p className="font-semibold text-white uppercase">{t(message)}</p>
    </div>
  );
}
