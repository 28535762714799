import React from 'react';

import Message from 'ui/Message';

import logo from 'assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export default function Layout({ title, message, error, children }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-300">
      <div className="container flex flex-col items-center justify-center mx-auto space-y-8">
        <div className="flex flex-row">
          <img alt="MyOfferings Logo" src={logo} className="w-56" />
        </div>
        <div className="flex flex-col items-stretch bg-gray-100 rounded-lg max-w-sm shadow">
          <div className="flex flex-col items-center pt-5 pb-4 bg-gray-200 rounded-t-lg border-b">
            <h1 className="text-xl text-gray-700">{title}</h1>
          </div>
          {message && (
            <div className="flex flex-col items-center px-10 py-5 ">
              <Message message={t(message)} error={error} />
            </div>
          )}
          {children && (
            <div className="flex flex-col items-center p-10 pt-5">
              <div className="flex flex-col items-center">{children}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
