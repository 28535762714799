import React from 'react';

import { useTranslation } from 'react-i18next';

import Layout from 'ui/Layout';

export default function NotFound() {
  const { t } = useTranslation();

  return <Layout message={t('pageNotFound')} error />;
}
