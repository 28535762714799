import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import app from 'config/app';
import Submit from 'ui/Submit';
import Form from 'ui/Form';
import Input from 'ui/Input';
import Layout from 'ui/Layout';
import apiCall from 'utils/apiCall';

function VerifyAccount() {
  const { t } = useTranslation('resetPassword');
  const { token } = useParams();

  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    document.title = `${app.name} | ${t('title')}`;
  }, [t]);

  useEffect(() => {
    if (!token) {
      setError(true);
      setShowForm(false);
      setMessage('missingParamsMessage');
    }
  }, [token, t]);

  const onPasswordSet = useCallback(
    ({ newPassword }) => {
      apiCall({
        endpoint: 'resetPassword',
        data: { newPassword },
        token,
        onSuccess: () => {
          setError(false);
          setShowForm(false);
          setMessage('successMessage');
        },
        onError: () => {
          setError(true);
          setMessage('errorMessage');
        },
      });
    },
    [token],
  );

  return (
    <Layout title={t('title')} message={t(message)} error={error}>
      {showForm && (
        <Form className="space-y-4" onSubmit={onPasswordSet}>
          <Input
            name="newPassword"
            type="password"
            label={t('newPassword')}
            required
          />
          <Input
            name="newPasswordConfirm"
            type="password"
            label={t('newPasswordConfirm')}
            validate={({ getValues }) => (value) => {
              const password = getValues('newPassword');
              return password === value || t('form:passwordMatchError');
            }}
            required
          />
          <div className="flex items-center justify-center pt-4">
            <Submit label={t('resetPassword')} />
          </div>
        </Form>
      )}
    </Layout>
  );
}

export default VerifyAccount;
