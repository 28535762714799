import React, { useCallback, useEffect, useReducer, useState } from 'react';

import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import Message from 'ui/Message';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Item from 'ui/Item';
import Section from 'ui/Section';
import apiCall from 'utils/apiCall';
import { isFunction } from 'utils/typeChecks';

const availableCards = ['amex', 'mastercard', 'visa'];

const stripeCardStyles = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Noto Sans',
      color: '#2d3748', // text-gray-800
      '::placeholder': {
        color: '#a0aec0', // text-gray-500
      },
    },
  },
};

const paymentRequestButtonStyle = {
  paymentRequestButton: {
    type: 'donate',
    // One of 'default', 'book', 'buy', or 'donate'
    // Defaults to 'default'

    theme: 'light-outline',
    // One of 'dark', 'light', or 'light-outline'
    // Defaults to 'dark'

    height: '40px',
    // Defaults to '40px'. The width is always '100%'.
  },
};

const initialState = {
  ready: false,
  valid: false,
  started: false,
  error: null,
  succeeded: false,
  cardId: null,
  firstCardSetted: false,
  message: null,
};

const READY = 'READY';
const START = 'START';
const USE_CARD = 'USE_CARD';
const SET_FIRST_CARD = 'SET_FIRST_CARD';
const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const NEW_CARD_UPDATED = 'NEW_CARD_UPDATED';

function paymentReducer(state, action) {
  switch (action.type) {
    case READY:
      return {
        ...state,
        ready: true,
      };
    case START:
      apiCall({
        endpoint: 'setProcessingStarted',
        params: {
          id: action.payload.transactionId,
        },
      });

      return {
        ...state,
        processing: true,
      };
    case SET_FIRST_CARD: {
      const cardId = state.cardId === action.payload ? null : action.payload;
      return {
        ...state,
        cardId,
        firstCardSetted: true,
        valid: cardId !== null,
      };
    }
    case USE_CARD: {
      const cardId = state.cardId === action.payload ? null : action.payload;
      return {
        ...state,
        cardId,
        valid: cardId !== null,
      };
    }
    case ERROR:
      return {
        ...state,
        processing: false,
        error: true,
        message: action.payload,
      };
    case SUCCESS:
      return {
        ...state,
        processing: false,
        succeeded: true,
        message: 'payment_completed',
      };

    case NEW_CARD_UPDATED:
      const { complete } = action.payload;

      return complete
        ? {
            ...state,
            cardId: null,
            valid: true,
          }
        : state;
    default:
      throw new Error();
  }
}

export default function CardForm({
  transactionId = '',
  secret = '',
  paymentMethods = [],
  country = '',
  currency = '',
  total = 0,
  onStart = () => {},
  onSuccess = (paymentIntent) => {},
  onError = (error) => {},
}) {
  const { t } = useTranslation('payment');
  const stripe = useStripe();
  const elements = useElements();
  const [state, dispatch] = useReducer(paymentReducer, initialState);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const redirectToSuccess = useCallback(() => {
    window.location.href = '/payment-finished';
  }, []);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Inform that payment process has started
    if (isFunction(onStart)) onStart();

    // Payment processing is starting
    dispatch({ type: START, payload: { transactionId } });

    const { paymentIntent, error } = await stripe.confirmCardPayment(secret, {
      payment_method: state.cardId || {
        card: elements.getElement(CardElement),
      },
      setup_future_usage: 'on_session',
    });

    if (error) {
      dispatch({ type: ERROR, payload: error.code });
      // Show error message to customer (e.g., insufficient funds)
      if (isFunction(onError)) onError(error);
    } else {
      // The payment has been processed!
      if (paymentIntent.status === 'succeeded') {
        redirectToSuccess();
      }
    }
  };

  // This is based on the example shown here:
  // https://stripe.com/docs/stripe-js/elements/payment-request-button#react-complete-payment
  const handleWalletSubmit = useCallback(
    async (event) => {
      // Inform that payment process has started
      if (isFunction(onStart)) onStart();

      // Payment processing is starting
      dispatch({ type: START, payload: { transactionId } });

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        secret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false },
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        event.complete('fail');

        dispatch({ type: ERROR, payload: confirmError.code });
        // Show error message to customer (e.g., insufficient funds)
        if (isFunction(onError)) onError(confirmError);
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11" instead
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(secret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            dispatch({ type: ERROR, payload: error.code });
            // Show error message to customer (e.g., insufficient funds)
            if (isFunction(onError)) onError(error);
          } else {
            // The payment has succeeded.
            redirectToSuccess();
          }
        } else {
          // The payment has succeeded.
          redirectToSuccess();
        }
      }
    },
    [onError, onStart, redirectToSuccess, secret, stripe, transactionId],
  );

  const onNewReady = useCallback((element) => {
    dispatch({ type: READY });
  }, []);

  const onNewChange = useCallback((card) => {
    dispatch({ type: NEW_CARD_UPDATED, payload: card });
  }, []);

  useEffect(() => {
    if (paymentMethods?.length && !state.cardId && !state.firstCardSetted) {
      const firstCard = paymentMethods[0];
      dispatch({ type: SET_FIRST_CARD, payload: firstCard.id });
    }
  }, [paymentMethods, state]);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country,
        currency,
        total: {
          label: t('donationTotal'),
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          pr.on('paymentmethod', handleWalletSubmit);
          setPaymentRequest(pr);
        }
      });
    }
  }, [country, currency, handleWalletSubmit, total, stripe, t]);

  if (!stripe) return null;

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between h-screen bg-gray-200"
    >
      <div className="flex-grow pb-24">
        {state.message && (
          <Message
            className="m-4"
            message={t(state.message)}
            error={state.error}
          />
        )}
        {paymentRequest && (
          <Section innerClass="px-4 py-5" title={t('applePay')}>
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: paymentRequestButtonStyle,
                disabled: state.processing,
              }}
            />
          </Section>
        )}
        {paymentMethods?.length > 0 && (
          <Section title={t('availableCards')}>
            {paymentMethods.map(({ id, card }, i) => (
              <Item
                key={`${id}-card-${i}`}
                selectable
                selected={id === state.cardId}
                icon={
                  <Icon
                    className="text-2xl bg-white border border-gray-300 rounded"
                    name={
                      availableCards.includes(card.brand) ? card.brand : 'card'
                    }
                  />
                }
                label={<span className="font-mono">{`••••${card.last4}`}</span>}
                value={`${card.exp_month}/${card.exp_year}`}
                onClick={() => dispatch({ type: USE_CARD, payload: id })}
              />
            ))}
          </Section>
        )}
        <Section title={t('addCard')}>
          <CardElement
            className={state.ready ? 'h-auto px-4 py-5' : 'h-0'}
            onReady={onNewReady}
            onChange={onNewChange}
            options={stripeCardStyles}
          />
        </Section>
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex justify-center w-full p-4 pb-6 bg-gray-200">
        <Button
          label={t(state.processing ? 'processing' : 'donate')}
          icon="donate"
          loading={state.processing}
          disabled={!state.valid || state.processing || state.succeeded}
          type="submit"
          size="default"
        />
      </div>
    </form>
  );
}
