import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './locales/de';
import en from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import it from './locales/it';
import pt from './locales/pt';
import ro from './locales/ro';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { de, en, es, fr, it, pt, ro },
    fallbackLng: 'en',

    defaultNS: 'common',
    ns: ['common'],

    detection: {
      order: [
        'navigator',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
      ],
      cookieMinutes: 10,
    },

    interpolation: {
      escapeValue: false,
    },
  });
