import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext, Controller } from 'react-hook-form';

import Field from './Field';
import Icon from './Icon';
import { isFunction } from 'utils/typeChecks';

const validationPatterns = {
  email: {
    value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: 'form:invalidEmailFormat',
  },
  password: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    message: 'form:passwordPatternError',
  },
};

export default function Input({
  defaultValue,
  icon,
  label,
  max,
  maxLenght,
  min,
  minLenght,
  name,
  pattern,
  placeholder,
  required = false,
  showChecked = true,
  showRequired = true,
  validate,
  type = 'text', // 'decimal','email','number','password','phone','text',
}) {
  const isPassword = type === 'password';

  const [hasFocus, setHasFocus] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const error = errors[name];

  const _onBlur = useCallback(
    (onBlur) => (e) => {
      onBlur(e);
      setHasFocus(false);
    },
    [],
  );

  const _onFocus = useCallback((e) => {
    setHasFocus(true);
  }, []);

  const _onTogglePassword = useCallback(
    (e) => {
      e.preventDefault();
      setHidePassword(!hidePassword);
    },
    [hidePassword],
  );

  const rules = useMemo(
    () => ({
      pattern: validationPatterns[pattern || type],
      validate: isFunction(validate) ? validate({ getValues }) : validate,
      max,
      min,
      maxLenght,
      minLenght,
      required,
    }),
    [
      getValues,
      pattern,
      validate,
      max,
      min,
      maxLenght,
      minLenght,
      required,
      type,
    ],
  );

  // abcDEF12

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onBlur, onChange, value } }) => (
        <Field
          error={error}
          hasFocus={hasFocus}
          hasValue={!!value}
          label={label}
          required={required}
          showChecked={showChecked}
          showRequired={showRequired}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="flex-1">
              <input
                name={name}
                className="p-3 text-lg text-gray-900 placeholder-gray-400 bg-transparent outline-none"
                placeholder={placeholder}
                type={isPassword && !hidePassword ? 'text' : type}
                onBlur={_onBlur(onBlur)}
                onChange={onChange}
                onFocus={_onFocus}
                defaultValue={value}
              />
            </div>

            {isPassword && value ? (
              <button
                className="flex-shrink-0 px-2 outline-none cursor-pointer"
                onClick={_onTogglePassword}
              >
                <Icon
                  name={hidePassword ? 'eye' : 'eye-slash'}
                  className="text-gray-500"
                />
              </button>
            ) : (
              icon && (
                <div className="flex-shrink-0 px-2">
                  <Icon name={icon} color="gray-500" />
                </div>
              )
            )}
          </div>
        </Field>
      )}
    />
  );
}
