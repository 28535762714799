import React from 'react';

import Icon from './Icon';

export default function Item({
  label,
  icon,
  sublabel,
  value,
  to,
  selected = false,
  selectable = false,
  onClick,
}) {
  return (
    <a href={to} onClick={onClick}>
      <div
        className={`flex flex-row justify-between items-center p-4 bg-white border-solid border-gray-200 border-b transition-colors ease-in-out ${
          selected ? 'bg-green-100 text-green-700' : ''
        }`}
      >
        <div className="flex items-center flex-shrink mr-2 select-none flex-rows">
          {selectable && (
            <div className="mr-2">
              <Icon name={selected ? 'check-circle' : 'circle'} />
            </div>
          )}
          {icon && <div className="mr-2">{icon}</div>}
          <div className="flex flex-col flex-shrink">
            {sublabel && (
              <span className="mb-2 text-sm text-blue-600">{sublabel}</span>
            )}
            <span className="font-semibold">{label}</span>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div>
            {typeof value === 'string' ? (
              <span className="opacity-75">{value}</span>
            ) : (
              value
            )}
          </div>
          {to && (
            <div className="ml-2">
              <Icon name="chevron-right" />
            </div>
          )}
        </div>
      </div>
    </a>
  );
}
