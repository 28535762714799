import React, { useEffect, useMemo } from 'react';

import { decode } from 'js-base64';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import app from 'config/app';
import CardForm from 'components/CardForm';
import Message from 'ui/Message';
import { isFunction } from 'utils/typeChecks';
import 'config/i18n/index';

const stripeOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap',
    },
  ],
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function StripePayment() {
  const { i18n, t } = useTranslation('payment');
  const query = useQuery();

  const payload = JSON.parse(decode(query.get('payload')));

  const {
    transactionId,
    publicKey,
    paymentMethods,
    secret,
    locale,
    country,
    currency,
    total,
  } = payload || {};

  useEffect(() => {
    document.title = `${app.name} | ${t('donate')}`;
  }, [t]);

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, locale]);

  const stripePromise = useMemo(() => {
    if (!publicKey) return null;
    return loadStripe(publicKey, { locale: i18n.language });
  }, [publicKey, i18n]);

  if (!payload) return null;

  if (!publicKey) {
    console.error('Missing publicKey'); // eslint-disable-line no-console
    return <Message message="Something went wrong!" error className="m-4" />;
  }

  return (
    <Elements stripe={stripePromise} options={{ ...stripeOptions, locale }}>
      <div className="font-sans text-base antialiased">
        <CardForm
          transactionId={transactionId}
          secret={secret}
          paymentMethods={paymentMethods}
          country={country}
          currency={currency}
          total={total}
          onStart={() => {
            if (isFunction(window.onPayStart)) window.onPayStart();
          }}
          onSuccess={(data) => {
            if (isFunction(window.onPaySuccess)) window.onPaySuccess(data);
          }}
          onError={(data) => {
            if (isFunction(window.onPayError)) window.onPayError(data);
          }}
        />
      </div>
    </Elements>
  );
}

export default StripePayment;
